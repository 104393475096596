import { useConnect } from "@stacks/connect-react";
import { StacksTestnet } from "@stacks/network";
import {
  AnchorMode,
  PostConditionMode,
  stringUtf8CV,
  PrincipalCV,
  parsePrincipalString
} from "@stacks/transactions";
import { principalCV } from "@stacks/transactions/dist/clarity/types/principalCV";
import Wallet,{ userSession } from "./wallet";


const ContractCallVote = () => {
  const { doContractCall } = useConnect();
  const breakpoints = [
    { width: 576, wordsPerLine: 4,isMobile:true },
    { width: 768, wordsPerLine: 6,isMobile:true },
    { width: 992, wordsPerLine: 8,isMobile:false },
    { width: 1200, wordsPerLine: 10,isMobile:false },
    { width: Infinity, wordsPerLine: 14,isMobile:false }
  ];

  const { wordsPerLine,isMobile } = breakpoints.find(({ width }) => width >= window.innerWidth);

  function join() {
    doContractCall({
      network: new StacksTestnet(),
      anchorMode: AnchorMode.Any,
      contractAddress: "ST1EM1QREZ5DMC93HJB4BYFZWF8V4EV0EZW8BWYJZ",
      contractName: "whitelist2",
      functionName: "add-to-whitelist",
      functionArgs: [principalCV(userSession.loadUserData().profile.stxAddress.mainnet)],
      postConditionMode: PostConditionMode.Deny,
      postConditions: [],
      onFinish: (data) => {
        console.log("onFinish:", data);
        window
          .open(
            `https://explorer.stacks.co/txid/${data.txId}?chain=testnet`,
            "_blank"
          )
          .focus();
      },
      onCancel: () => {
        console.log("onCancel:", "Transaction was canceled");
      },
    });
  }

  if (!userSession.isUserSignedIn()) {
    return null;
  }

  return (
    <div>
      <div className="whitelist" onClick={() => join()}>
      <img src="../assets/waitlisticon.png" className="wait-selected" style={{ width: isMobile? "20%":"80%" }} />
      </div>
    </div>
  );
};

export default ContractCallVote;
