import { h } from 'preact';
import { Router } from 'preact-router';

import Header from './header';

import { Connect } from "@stacks/connect-react";

import { userSession } from "../routes/home/wallet";

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import WhitePaper from "../routes/Whitepaper";
import Chat from "../routes/chat";



import React, { useState } from 'react';

import speakerIcon from './speaker-icon.svg';
const AudioControls = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPauseClick = () => {
    const audio = document.getElementById('audio');
    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className="audio-controls">
      <Header/>
      <audio id="audio" src="../assets/welcome.mp3" hidden />
      <button className="play-pause-button" onClick={handlePlayPauseClick}>
        <img
          className={`speaker-icon ${isPlaying ? 'is-playing' : ''}`}
          src={speakerIcon}
          alt="Speaker icon"
          style={{maxHeight:40,borderRadius:50}}
        />
      </button>
    </div>
  );
};






const App = () => (
	<div  style={{backgroundColor:"black",minHeight:1000,minWidth:333}}>
    <AudioControls/>
		<main style={{backgroundColor:"black"}}>
		<Connect
      authOptions={{
        appDetails: {
      name: "Medieval Hackers",
      icon: "https://medieval-hackers.pages.dev/assets/collection1.png",
        },
        redirectTo: "/",
        onFinish: () => {
          window.location.reload();
        },
        userSession,
      }}
    >
			<Router>
				<Home path="/" />
				<WhitePaper path="/whitepaper" />
			</Router>
			</Connect>
		</main>
	</div>
);

export default App;
