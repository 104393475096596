import React from "react";
import { AppConfig, showConnect, UserSession } from "@stacks/connect";
import Whitelist from "./whitelist";
const appConfig = new AppConfig(["store_write", "publish_data"]);

export const userSession = new UserSession({ appConfig });

function authenticate() {
  showConnect({
    appDetails: {
      name: "Medieval Hackers",
      icon: "https://medieval-hackers.pages.dev/assets/collection1.png",
    },
    redirectTo: "/",
    onFinish: () => {
      window.location.reload();
    },
    userSession,
  });
}

function disconnect() {
  userSession.signUserOut("/");
}

const ConnectWallet = ({showBanner}) => {
  const breakpoints = [
    { width: 576, wordsPerLine: 4,isMobile:true },
    { width: 768, wordsPerLine: 6,isMobile:true },
    { width: 992, wordsPerLine: 8,isMobile:false },
    { width: 1200, wordsPerLine: 10,isMobile:false },
    { width: Infinity, wordsPerLine: 14,isMobile:false }
  ];

  const {isMobile } = breakpoints.find(({ width }) => width >= window.innerWidth);


  if (userSession.isUserSignedIn()) {
    return (
      <div>
        <button className="Connect" style={{margin:20,marginLeft:50}} onClick={disconnect}>
         Log out
        </button>
      </div>
    );
  }

  if(!showBanner || isMobile){
    return (
      <div className="whitelist" onClick={authenticate}>
      <img src="../assets/wallet.png" className="wait-selected" style={{ width: isMobile? "20%":"30%" }} />
      </div>
    );
  }

  return (
    <button className="Connect" style={{margin:20,marginLeft:50}} onClick={authenticate}>
      Connect Wallet
    </button>
  );


};

export default ConnectWallet;